<script setup>
import {computed} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

defineProps({
    type: {
        type: String,
        default: 'submit',
    },
});

const page = computed(()=>usePage());
const textColor = computed(() => '#FFFFFF');
const primaryColor = computed(() => page.value.props.value.secondaryColor);

</script>

<template>
    <button :type="type"
            :style="['color:'+ textColor, 'background-color:'+primaryColor]"
            class="rounded-full justify-center inline-flex items-center px-4 py-2 border border-transparent font-semibold text-xs uppercase tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition">
        <slot />
    </button>
</template>
